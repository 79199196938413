<template>
  <ERow class="white demos w-50 w-100 h-100 mx-0 overflow-hidden">
    <ERow class="demos__controls px-2 d-flex align-center">
      <ECol>
        <!-- Projects -->
        <Autocomplete
          v-model="project"
          item-value="exid"
          item-text="name"
          label="Projects"
          append-icon="fa-sitemap"
          no-filter
          hide-details
          dense
          :cache-items="false"
          resource="projects"
          :provider-params="projectParams"
          @change="startDemo('project')"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item
              two-line
              dense
              v-bind="attrs"
              @click="checkNDAFlagStatus(item)"
              v-on="on"
            >
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <NdaIcon v-if="isNDAProject(item)" />
                  <span class="primary--text">{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.exid }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </Autocomplete>
      </ECol>
      <ECol>
        <!-- Cameras -->
        <Autocomplete
          v-model="camera"
          item-value="exid"
          item-text="name"
          label="Cameras"
          append-icon="fa-camera"
          no-filter
          hide-details
          dense
          :cache-items="false"
          resource="cameras"
          @change="startDemo('camera')"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item
              two-line
              dense
              v-bind="attrs"
              @click="checkNDAFlagStatus(item)"
              v-on="on"
            >
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <NdaIcon v-if="isNDAProject(item)" />
                  <span class="primary--text">{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.exid }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </Autocomplete>
      </ECol>

      <ECol>
        <!-- Users -->
        <Autocomplete
          v-model="user"
          item-value="email"
          item-text="name"
          label="Users"
          append-icon="far fa-user"
          no-filter
          hide-details
          dense
          :cache-items="false"
          resource="users"
          @change="startDemo('user')"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item two-line dense v-bind="attrs" v-on="on">
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  <span class="primary--text">{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </Autocomplete>
      </ECol>

      <ECol>
        <!-- Keywords -->
        <Autocomplete
          v-model="keyword"
          item-value="id"
          item-text="name"
          label="Keywords"
          append-icon="fa-tag"
          no-filter
          hide-details
          dense
          :cache-items="false"
          :provider="searchKeywords"
          search-on-focus
          @change="onKeywordSelected"
        />
      </ECol>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn
              v-bind="attrs"
              icon
              class="mr-4"
              :disabled="!demoIsActive"
              v-on="on"
              @click="toggleFullScreen"
            >
              <v-icon class="v-icon--custom" :size="hover ? 22 : 20">
                fa-expand</v-icon
              >
            </v-btn>
          </v-hover>
        </template>
        <span>Full screen</span>
      </v-tooltip>
    </ERow>
    <ECol
      v-if="selectedKeyword && selectedKeyword.items.length"
      ref="keywordsContainer"
      cols="12"
      class="d-flex justify-space-between"
    >
      <div>
        <v-chip
          v-for="{ exid, name } in selectedKeyword.items"
          :key="exid"
          x-small
          class="ma-1"
          @click="onChipClick(exid)"
        >
          <strong>{{ name }}</strong
          >&nbsp;
          <span>({{ exid }})</span>
        </v-chip>
      </div>
      <v-icon class="d-flex align-start mx-2" @click="closeKeywordsPanel">
        fa-xmark</v-icon
      >
    </ECol>
    <ECol ref="demoContainer" cols="12" class="w-100 h-100 pa-0 pt-1">
      <NuxtChild v-if="demoIsActive" :height="iframeHeight" />
      <div
        v-else
        class="demos__iframe d-flex flex-column justify-center align-center w-100"
        :style="{ height: iframeHeight + 'px' }"
      >
        <v-icon class="v-icon--custom" size="120"> fa-desktop </v-icon>
      </div>
    </ECol>
  </ERow>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"
import NdaIcon from "@/components/NdaIcon"
import { CameraFeatureFlag, ProjectFeatureFlag } from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  name: "Demo",
  components: {
    Autocomplete,
    NdaIcon,
  },
  data() {
    return {
      project: null,
      camera: null,
      user: null,
      keyword: null,
      selectedKeyword: { items: [] },
      iframeStyle: "",
      selectedUrl: "",
      iframeHeight: null,
      selectedResourceRoute: "cameras",
      keywords: [
        {
          name: "BIM Compare",
          id: CameraFeatureFlag.BimCompare,
          page: "/bim-compare",
        },
        { name: "ANPR", id: CameraFeatureFlag.ANPR, page: "/anpr" },
        {
          name: "Gate report",
          id: ProjectFeatureFlag.GateReport,
          page: "/gate-report",
        },
        {
          name: "BIM",
          id: ProjectFeatureFlag.Bim,
          page: "/bim",
        },
        {
          name: "Drone View",
          id: ProjectFeatureFlag.DroneView,
          page: "/drone",
        },
        {
          name: "360 View",
          id: ProjectFeatureFlag.ThreeSixtyView,
          page: "/360",
        },
      ],
    }
  },
  head() {
    return {
      title: "Admin - Demos",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },

  computed: {
    demoIsActive() {
      return !!Object.keys(this.$route.params).length
    },
  },
  mounted() {
    document.documentElement.classList.add("overflow-y-hidden")
    this.updateIframeHeight()
    this.$nextTick(() => {
      this.$addEventListener("resize", this.onResize)
    })
  },
  methods: {
    isNDAProject(item) {
      return (
        item?.featureFlags?.includes(ProjectFeatureFlag.NdaMc) ||
        item?.projectFeatureFlags?.includes(ProjectFeatureFlag.NdaMc)
      )
    },
    startDemo(resource) {
      this.selectedKeyword = { items: [] }
      this.$nextTick(() => {
        this.updateIframeHeight()
      })
      switch (resource) {
        case "project":
          if (this.project) {
            this.camera = null
            this.user = null
            this.keyword = null
            this.selectedUrl = `/demos/projects/${this.project.exid}`
            this.project?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
              ? this.openNdaImpersonationDialog()
              : this.$router.push(this.selectedUrl)
          }
          break
        case "camera":
          if (this.camera) {
            this.project = null
            this.user = null
            this.keyword = null
            this.selectedUrl = `/demos/cameras/${this.camera.exid}`
            this.camera?.projectFeatureFlags?.includes(ProjectFeatureFlag.NdaMc)
              ? this.openNdaImpersonationDialog()
              : this.$router.push(this.selectedUrl)
          }
          break
        case "user":
          if (this.user) {
            this.camera = null
            this.project = null
            this.keyword = null
            this.$router.push(`/demos/users/${this.user.email}`)
          }
          break
      }
    },
    searchKeywords({ params }) {
      const term = params.term?.toLowerCase()

      return new Promise((resolve) => {
        if (!term) {
          resolve({ items: this.keywords })
        }

        const items = this.keywords.filter(
          (k) => k.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
        )
        resolve({ items })
      })
    },
    onKeywordSelected(keyword) {
      this.camera = null
      this.user = null
      this.project = null
      if (!keyword?.id) {
        return
      }
      if (
        [CameraFeatureFlag.BimCompare, CameraFeatureFlag.ANPR].includes(
          keyword.id
        )
      ) {
        this.searchByResource("cameras", keyword.id)
        this.selectedResourceRoute = "cameras"
      } else if (
        [
          ProjectFeatureFlag.GateReport,
          ProjectFeatureFlag.Bim,
          ProjectFeatureFlag.DroneView,
          ProjectFeatureFlag.ThreeSixtyView,
        ].includes(keyword.id)
      ) {
        this.searchByResource("projects", keyword.id)
        this.selectedResourceRoute = "projects"
      } else {
        this.selectedKeyword = { items: [] }
      }

      this.$nextTick(() => {
        this.updateIframeHeight()
      })
    },
    onResize() {
      this.updateIframeHeight()
    },
    updateIframeHeight() {
      if (window.innerHeight == screen.height) {
        this.iframeHeight = screen.height
      } else {
        let keywordsContainerHeight =
          this.$refs?.keywordsContainer?.$el?.getBoundingClientRect()?.height
        this.iframeHeight =
          window.innerHeight -
          65 -
          (keywordsContainerHeight ? keywordsContainerHeight : 0)
      }
    },
    toggleFullScreen() {
      this.$refs.demoContainer?.$el?.requestFullscreen()
      this.updateIframeHeight()
    },
    closeKeywordsPanel() {
      this.selectedKeyword = { items: [] }
      this.$nextTick(() => {
        this.updateIframeHeight()
      })
    },
    projectParams(val) {
      return {
        term: val,
        email: "construction@evercam.io",
      }
    },
    async searchByResource(resource, featureFlag) {
      this.selectedKeyword = await AdminApi.search.search({
        params: {
          resource: resource,
          term: "",
          featureFlag,
          email: "construction@evercam.io",
          limit: 200,
        },
      })
    },
    onChipClick(exid) {
      const page = this.keywords.find(
        (item) => item.id === this.keyword?.id
      )?.page
      this.$router.push(
        `/demos/${this.selectedResourceRoute}/${exid}${
          page ? `?page=${page}` : ""
        }`
      )
    },
    checkNDAFlagStatus(item) {
      if (this.isNDAProject(item)) {
        this.openNdaImpersonationDialog()
      }
    },
    async openNdaImpersonationDialog() {
      if (
        await this.$confirmDialog.open({
          title: "NDA/MC",
          message:
            "This Project/Camera is under <span class='font-weight-bold'>Non Disclosure Agreement / Mission Critical (NDA/MC)</span> tag. Are you sure you want to impersonate?",
        })
      ) {
        this.$router.push(this.selectedUrl)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.demos {
  &__controls {
    box-shadow: none;
    .v-icon {
      transform: none !important;
    }
  }
}
</style>
